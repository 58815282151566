export const configuration = {
  cedApiKey: "rMZdFEAf9AQqoyYp3Kxzn8xlVDGnUC0XkGs5PQYYKCXQJmLkJl3jv3VkneSUpES8jbemmRQQZsoAOoxxbtJxdgvqnvNz5lBzSgXC",
  environment: "production",
  version: "1.0.122",
  backend: {
    graphqlUrl: "https://newslit.co/graphql",
    graphiqlUrl: "https://newslit.co/_ah/graphql",
    externalLoginUrl: "https://newslit.co/auth/external",
    baseUrl: "https://newslit.co",
  },
  fullstory: {
    orgId: "M2BV9",
  },
  honeybadger: {
    apiKey: "260ba5e7",
    environmentName: "production",
  },
  intercom: {
    appId: "naqx6y79",
  },
  launchdarkly: {
    clientSideId: "5b1d835e0b0fdd34a784f99f",
    sdkKey: "sdk-2d37f499-d54e-4ef1-8f1e-0ddb86cecca1",
  },
  newslitUikit: {
    registry: "https://registry.npmjs.org/",
  },
  reader: {
    hostingUrl: "https://newslit-reader-prod.web.app",
    url: "https://reader.newslit.co",
  },
  segment: {
    apiKey: "TyHTTt9x67LZrQxLAu58TnRfoYdTcmZC",
  },
  stripe: {
    publishableKey: "pk_live_724y7tQUh7sCsZzImOAmVqe2",
  },
  zapier: {
    embeddedWidgetUrl: "https://zapier.com/apps/embed/widget.js?services=newslit&limit=5&html_id=js-zapier-integration-embed",
  },
};